import { Grid } from '@mui/material'
import Servicios from './Servicios'
import Banner from './Banner'
import ButtonWsp from './ButtonWsp'
import Description from './Description'
import ZonaCobertura from './ZonaCobertura'
import Footer from './Footer'
import Fotos from './Fotos'

const Home = () => {
    let url = `https://api.whatsapp.com/send?phone=+3512259117&text=Hola%20Audisio!%20visité%20tu%20p%C3%A1gina%20web%20y%20y%20me%20gustaria%20saber%20mas%20sobre%20los%20servicios%20de%20desinfecciones!`
    return (
        <Grid container>
            <ButtonWsp url={url} />
            <Banner />
            <Description />
            
            <Servicios />
            <ZonaCobertura />
            <Fotos />
            
            <Footer />
        </Grid>
    )
}

export default Home