import { Grid } from "@mui/material"
import Foto1 from "../../../Assets/foto1.jpeg"
import Foto2 from "../../../Assets/foto2.jpeg"
import Foto3 from "../../../Assets/foto3.jpeg"
import Foto4 from "../../../Assets/foto4.jpeg"
import Foto5 from "../../../Assets/foto5.jpeg"
import Foto6 from "../../../Assets/foto6.jpeg"
import Foto7 from "../../../Assets/foto7.jpeg"
import Foto8 from "../../../Assets/foto8.jpeg"
import Foto9 from "../../../Assets/foto9.jpeg"
import Foto10 from "../../../Assets/foto10.jpeg"
import Foto11 from "../../../Assets/foto11.jpeg"
import Foto13 from "../../../Assets/foto13.jpeg"

const Fotos = () => {
    return (
        <Grid container mt={6} display="flex" justifyContent="center">
            <Grid item xs={11.2} bgcolor="#fff" className="p-1 rounded-top">
                <p className="titleText fs-5 text-center">ALGUNO DE NUESTROS TRABAJOS</p>
            </Grid>
            <Grid item xs={11.2}>
                <Grid container bgcolor="#fff" display="flex" justifyContent="center" pb={4} className="rounded-bottom">
                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto1} alt="foto operario 1" className="rounded img-works" />
                    </Grid>
                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto2} alt="foto operario 2" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto3} alt="foto operario 3" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto4} alt="foto operario 4" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto5} alt="foto operario 5" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto6} alt="foto operario 6" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto7} alt="foto operario 7" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto8} alt="foto operario 8" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto9} alt="foto operario 9" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto10} alt="foto operario 10" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto11} alt="foto operario 11" className="rounded img-works" />
                    </Grid>

                    <Grid item lg={2.5} xs={5} mx={0.75} mt={1}>
                        <img src={Foto13} alt="foto operario 13" className="rounded img-works" />
                    </Grid>

                </Grid>
            </Grid>
        </Grid>

    )
}

export default Fotos
