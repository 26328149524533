import { Grid } from "@mui/material"
import Background from "../../../Assets/background.png"

const Banner = () => {
    return (
        <Grid className='w-100' id="Inicio">
            <img src={Background} className="bg-banner" alt="fondo desinfeccion" />
        </Grid>
    )
}

export default Banner