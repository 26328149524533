import { Grid } from "@mui/material"
import Logo from "../../../Assets/logo.png"

const Description = () => {
    return (
        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'flex' } }} justifyContent="center" id="Servicios">
            <Grid item xs={11} lg={8} m={4} p={2} className="bg-white rounded">
                <h1 className='titleText m-4'>
                    AUDISIO FUMIGACIONES
                </h1>
                <p className='fs-5 m-4'>
                    Somos una compañía especializada en Control de Plagas, Fumigaciones, Desratizacion, Desinfeccion,  Higiene y Saneamiento Ambiental, con el propósito de eliminar los factores de riesgo y hacer los ambientes más sanos y saludables para sus ocupantes.
                </p>
                <p className='fs-5 m-4'>
                    Con estos objetivos, hemos desarrollado distintas metodologías que garantizan la máxima efectividad de las fumigaciones y completa seguridad para personas, animales y bienes materiales.
                </p>
            </Grid>
            <Grid item xs={11} lg={3} m={4} p={2} className="bg-white rounded" display="flex" alignItems="center" justifyContent="center">
                <img src={Logo} style={{ width: "300px", height: "auto" }} alt="fondo desinfeccion" />
            </Grid>
        </Grid>
    )
}

export default Description