import * as React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Divider,
  ListItemText,
  Grid
} from "@mui/material"

import MenuIcon from '@mui/icons-material/Menu';
import Logo from "../../Assets/ICO.png";
import { Link } from "react-scroll";

const drawerWidth = 240;
const navItems = [
  { name: 'Inicio', path: 30 },
  { name: 'Servicios', path: 50 },
  { name: 'Sobre Nosotros', path: 75 },
  { name: 'Contacto', path: 100 },
];

const NavBar = (props: any) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <div className="d-block">
          <img src={Logo} style={{ width: "50px", height: "auto" }} alt="fondo desinfeccion" />
          <div className="fw-bold">Audisio Desinfecciones</div>
        </div>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center', color: "black" }}>
              <Link to={`${item.name}`} spy={true} smooth={true} offset={50} duration={500}>
                {item.name}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar className='bg-white text-dark'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            className="d-flex"
          >
            <img src={Logo} style={{ width: "50px", height: "auto" }} alt="fondo desinfeccion" />
            <div className="mx-4 mt-2"> Audisio Desinfecciones</div>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Link key={item.name} to={`${item.name}`} spy={true} smooth={true} offset={20} duration={50}>
                <Button className='text-dark'>
                  {item.name}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Grid container>
        <Toolbar />
        {props.children}
      </Grid>
    </Box>
  );
}

export default NavBar