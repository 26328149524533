import { Grid } from "@mui/material"
import LogoWsp from "../../../Assets/icon-wsp.png"

const ButtonWsp = (url: any) => {
    return (
        <Grid item xs={12}>
            <div className='containerWsp'>
                <a href={url.url} target="_blank">
                    <img src={LogoWsp} className="iconWsp" alt="" />
                </a>
            </div>
        </Grid>
    )
}

export default ButtonWsp