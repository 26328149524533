import './App.scss';
import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './Components/NavBar';
import Home from './Components/Home';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NavBar><Home/></NavBar>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
