import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail'
import { Grid } from '@mui/material';

const Footer = () => {
    return (
        <Grid container display="flex" justifyContent="center" mt={3} id="Contacto">

            <Grid item xs={12} bgcolor="black" pt={2} className="rounded-top">
                <h4 className='text-white text-center display-6 fs-5'>CONTACTO</h4>
            </Grid>

            <Grid item xs={12}>
                <Grid container bgcolor="black" display="flex" justifyContent="center" pb={3} pt={2}>
                    <Grid item xs={11} lg={3} className="text-white fs-6 bg-wsp mx-2 rounded p-1 my-2">
                        <a rel="noreferrer" className="text-white text-decoration-none" href="https://api.whatsapp.com/send?phone=+3512259117&text=Hola%20Audisio!%20visité%20tu%20p%C3%A1gina%20web%20y%20y%20me%20gustaria%20saber%20mas%20sobre%20los%20servicios%20de%20desinfecciones!" target="_black">
                            <WhatsAppIcon className="fs-1" /> <b>Telefono:</b>  351-225-9117
                        </a>
                    </Grid>
                    <Grid item xs={11} lg={3} className="text-white fs-6 bg-primary mx-2 rounded p-1 my-2">
                        <a rel="noreferrer" href="https://www.facebook.com/people/Audisio-Fumigaciones/100063524622894/" target="_blank" className='text-white text-decoration-none'>
                            <FacebookIcon className="fs-1" /> <b>Facebook:</b>  Audisio Fumigaciones
                        </a>
                    </Grid>
                    <Grid item xs={11} lg={3} className="text-white fs-6 mx-2 bg-location rounded p-1 my-2">
                        <MailIcon className="fs-1" /> <b>Correo:</b> audisiodesinfecciones@gmail.com
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container bgcolor="black" display="flex" justifyContent="center" pb={3} pt={2}>

                    <Grid item xs={3} className="text-white d-flex justify-content-center">
                        <ul className="list-group">
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Desinfecciones</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Fumigaciones</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Industria</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Comercio</li>
                        </ul>
                    </Grid>

                    <Grid item xs={3} className="text-white d-flex justify-content-center">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Bacterias</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Virus</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Gérmenes</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Mosquito</li>
                        </ul>
                    </Grid>

                    <Grid item xs={3} className="text-white d-flex justify-content-center">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Pulga</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Arañas</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Hormigas</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Cucaracha</li>
                        </ul>
                    </Grid>

                    <Grid item xs={3} className="text-white d-flex justify-content-center">
                        <ul className="list-group">
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Ratas</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Murcielago</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Alacrán</li>
                            <li className="list-group-item bg-black text-white border-start" style={{fontSize: "12px"}}>Mantenimientos</li>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Footer
