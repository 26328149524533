import { Grid } from "@mui/material"
import AlacranUv from "../../../Assets/alacranUv.jpeg"
import Scorpion2 from "../../../Assets/scorpion2.jpg"
import Scorpion3 from "../../../Assets/scorpion3.jpg"

const ZonaCobertura = () => {
    return (
        <Grid container mt={5} display="flex" justifyContent="center" alignItems="start" id="Sobre Nosotros">
            <Grid item xs={11} lg={7.1} p={3} mx={2} className="bg-white text-center shadow">
                <h3 className='titleText'>Cobertura de Nuestros Servicios</h3>
                <Grid className="shadow">
                    <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1tXumT-6rwArxhK9eKnsoR8em1CoLays&ehbc=2E312F&z=11" title="frame mapa" allowFullScreen width="100%" height="650"></iframe>
                </Grid>
            </Grid>
            
            <Grid item xs={11} lg={4} className="mt-lg-0 mt-4 bg-white text-center shadow p-5">
                <h3 className='titleText'>Trabajamos con Tecnologia UV</h3>
                <p className="text-start py-2">Trabajamos con gama de luces ultravioleta para poder identificar o detectar las posibles plagas dentro de un lugar determinado y poder combatirlas con mayor eficiencia. Algunos de los insectos suelen emitir fluorescencia cuando se las expone a longitudes de onda de luz ultravioleta</p>
                <img src={AlacranUv} className="img-fluid rounded shadow" alt="foto alacran" />
                <div className="d-flex">
                    <img src={Scorpion2} alt="logo escorpion dos" className="img-fluid2 rounded mx-auto my-3" />
                    <img src={Scorpion3} alt="logo escorpion dos" className="img-fluid2 rounded mx-auto my-3" />
                </div>
            </Grid>
        </Grid>
    )
}

export default ZonaCobertura