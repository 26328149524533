import { Grid } from '@mui/material'
import Desinfecciones from "../../../Assets/desinfecciones.png"
import Fumigaciones from "../../../Assets/fumigacion.png"
import Industria from "../../../Assets/industria.png"
import Bacteria from "../../../Assets/bacteria.png"
import Plaga1 from "../../../Assets/plaga1.png"
import Plaga2 from "../../../Assets/plaga2.png"
import Organic from "../../../Assets/organico.png"
import ControlPlaga from "../../../Assets/controlPlaga.png"
import Comercio from "../../../Assets/comercio.png"
import Hogar from "../../../Assets/hogar.png"
const Servicios = () => {
    return (
        <Grid item xs={12}> 
            <Grid item xs={12} mt={4}>
                <h2 className='titleText text-center' >
                    NUESTROS SERVICIOS
                </h2>
                <Grid container justifyContent="center" display="flex">

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={Desinfecciones} className="img-card" alt="logo desinfecciones" />
                        <p className="textCard">Desinfecciones</p>
                    </Grid>

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={Fumigaciones} className="img-card" alt="logo Fumigaciones" />
                        <p className="textCard">Fumigaciones</p>
                    </Grid>

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={Industria} className="img-card" alt="logo Industria" />
                        <p className="textCard">Industria</p>
                    </Grid>

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={Bacteria} className="img-card" alt="logo Bacterias" />
                        <p className="textCard">Bacterias - Virus - Gérmenes</p>
                    </Grid>

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={Plaga1} className="img-card" alt="logo plagas" />
                        <p className="textCard">Mosquito - Pulga - Arañas - Hormigas</p>
                    </Grid>

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={Plaga2} className="img-card" alt="logo plagas2" />
                        <p className="textCard">Cucaracha - Ratas - Murciélago - Alacrán</p>
                    </Grid>

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={Organic} className="img-card" alt="logo plagas2" />
                        <p className="textCard">Fumigación Orgánica</p>
                    </Grid>

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={ControlPlaga} className="img-card" alt="logo plagas2" />
                        <p className="textCard">Control de Plagas en General</p>
                    </Grid>

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={Comercio} className="img-card" alt="logo Comercio" />
                        <p className="textCard">Comercios</p>
                    </Grid>

                    <Grid item xs={5} lg={2} className="m-1 m-lg-4 p-4 bg-white text-center hoverCard">
                        <img src={Hogar} className="img-card" alt="logo Hogares" />
                        <p className="textCard">Hogares</p>
                    </Grid>


                </Grid >

            </Grid>

        </Grid>
    )
}

export default Servicios